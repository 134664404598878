.contact {
    width: 100%;
}

.contact-header {
    display: flex;
    height: 42vh;
    font-family: 'DM Serif Display';
    background-repeat: no-repeat;
    background-image: url("../images/backgroung/contact-bg.jpg");
    margin-bottom: 10%;
    background-size: cover;
}

.contact-header>h1{
    /* width: 20%; */
    padding: 2% 3% 0.5% 3%;
    background-color: white;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    align-self: flex-end;
    text-align: center;
    margin: 0 auto;
    color: #292F36;
}

.contact-header>h1>p {
    color: #4D5053;
        font-size: 14px;
        font-family: Jost;
}

.contact-content {
    align-items: center;
    width: 70%;
    text-align: center;
    margin: 0 auto;
}

.contact-content>h2 {
    /* width: 50%; */
    font-size: 30px;
    padding: 10% 0% 5% 0%;
    margin: 0 auto;
}

.contact-form {
    display: flex;
}

.contact-form>h2 {
    text-align: center;
    font-family: DM Serif Display;
    width: 25%;
}

.contact-form-info {
    background-color: #F4F0EC;
    border-radius: 30px;
    padding: 5% 0%;
    width: 30%;
    margin: 15px auto;
}

.contact-form-info>div {
    text-align: center;
}

.contact-form-info>.icons>p{
    display: flex;
    align-items: center;
    /* padding-left: 10%; */
    padding-bottom: 5%;
}

.icon {
    border: 1px solid white;
    color: #CDA274;
    background-color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
}

.contact-smedias {
    margin-top: 20%;
}

.contact-smedias>ul {
    display: flex;
    padding: 0;
}

.contact-smedias>ul>li {
    list-style-type: none;
    margin-left: 10%;
}

.map {
    display: flex;
    justify-content: center;
    margin: 8% 0%;
}

iframe {
    border-radius: 20px;
}

.contact-alert-success-message { 
    background-color: #05ab00;
    padding: 5px;
    margin: 10px 20px;
    border-radius: 10px;
    color: #fff;
    font-weight: 700;
}
.contact-alert-error-message { 
    background-color: #ff2600;
    padding: 5px;
    margin: 10px 20px;
    border-radius: 10px;
    color: #fff;
    font-weight: 700;
}
.contact-input{
    text-align: left;
}
.contact-input input{
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    outline: none;
    margin-left: 25px;
    margin-top: 25px;
    border-bottom: 1px solid black;
    width: 100%;
}

.contact-input textarea {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    outline: none;
    margin-left: 25px;
    margin-top: 25px;
    resize: none;
    min-height: 100px;
    width: 100%;
}
.contact-form-design .send {
    width: 100%;
    margin-top: 8%;
    display: flex;
    justify-content: flex-end;
}
/* .contact-form-design button{
    padding: 10px;
    border-radius: 15px;
    background-color: #292F36;
    color: white;
    display: flex;
    justify-content: center;
    cursor: pointer;
} */

.contact-form-design .error-message{
    position: relative;
    font-size: 12px;
    color: #f00;
    font-weight: 500;
    padding-left: 25px;
}

.contact-details-card{
    background-color: #F4F0EC;
    border-radius: 25px;
    padding: 10px 25px;
    text-align: left;
}
.contact-details{
    display: flex;
    margin-bottom: 15px;
}
.contact-details .contact-icon{
    color: #cda274;
    border: 3px solid;
    border-color: #fff;
    padding: 2px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.contact-details p{
    margin-left: 15px;
}

.contact-details .word-break{
    word-break: break-word;
}

.contact-logos{
    text-align: center;
}
.contact-logos a{
    padding: 10px;
}

@media screen and (max-width: 768px){
    .contact-input input {
        margin-left: 0px;
    }

    .contact-input textarea {
        margin-left: 0px;
    }
}
