
/* Very small screens (e.g., mobile phones) */
@media (max-width: 480px) {
    .f-text p {
        font-size: 38px !important;
    }
}

/* Smaller screens (e.g., tablets) */
@media (max-width: 767px) {
    .f-text p {
        font-size: 38px !important;
    }
}

/* Larger screens (e.g., desktops) */
@media (min-width: 768px) {
    .f-text p {
        font-size: 20px !important;
    }
    .f-social-medias {
        border-right: 1px solid #0a6c8047;
    }
}

/* Extra large screens (e.g., large desktops or TVs) */
@media (min-width: 1200px) {
    .f-text p {
        font-size: 30px !important;
    }
    .f-social-medias {
        border-right: 1px solid #0a6c8047;
    }
}
@media (min-width: 768px) {
    .terms-list {
      flex-direction: row;
      justify-content: space-around;
    }
  
    .terms-list li {
      /* margin: 0; */
      margin: 10px;
    }

    
  }
  
  @media (max-width: 767px) {
    .terms {
      padding: 10px;
    }
  
    .terms-list a {
      font-size: 14px;
    }
  }

.footer {
    width: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 23%, rgba(1, 103, 124, 1) 100%);
    border-top: 1px solid #a4c9d0;
    box-shadow: 0 8px 10px rgb(133 140 148 / 15%), 0 0 2px #ffffff;
    padding-top: 30px;
    padding-bottom: 50px;
}

.f-info{
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.f-social-medias {
    /* width:30%; */
    padding-right: 2%;
}

/* .f-pages,.f-services {
     width: 19%; 
} */

.f-logo-text{
    font-family: 'DM Serif Display';
    font-size: 22px;
    display: flex;
    flex-direction: row;
}

.f-logo-text>a{
    text-decoration: none;
    color: black;
}

.f-text{
    /* width: 80%; */
    text-align: center;
    
}
.f-text p {
    margin: 25px 0px;
    font-family: monospace;
    text-transform: uppercase;
    font-weight: 600;
    color: #01677c;
    font-size: 24px;
}
/* .f-text p{
    margin: 0px;
    
} */

.f-logo {
    display: flex;
    width: 100%; 
    align-items: center;
    margin-right: 10px;
}

.f-logo>img{
    width: 100%;
}

.f-about-text>p {
    margin: 0;
    font-family: Jost;
    font-weight: 300;
    text-align: justify;
}

.f-smedia {
    margin: 8% 0;
}

.f-smedia>ul {
    display: flex;
    padding: 0;
}

.f-smedia>ul>li {
    list-style-type: none;
    margin-right: 15%;
}

.f-pages>p:first-child,
.f-services>p:first-child,
.f-contact>p:first-child{
    font-family: 'DM Serif Display';
    font-size: 18px;
    color: #000;
}

.f-pages>ul>li>a,
.f-services>ul>li>a,
.f-contact>p>a,
.f-contact>p{
    font-family: 'Jost';
    font-size: 15px;
    color: #000;
}

.f-pages>ul>li>a:hover,
.f-services>ul>li>a:hover {
    color: black;
}

.f-pages>ul>li,
.f-services>ul>li {
    margin-bottom: 5%;
    list-style-type: none;
}

.f-pages>ul,
.f-services>ul {
    padding: 0;
}


/* TERMS */
/* .terms {
    width: 60%;
    margin: 0 auto;
    display: flex;
} */

/* .terms>ul {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 5% 0 0 0;
}

.terms>ul>ol>a {
    color: #4D5053;
    font-size: 14px;
    padding: 0;
} */

.terms {
    /* width: 60%;
    margin: 0 auto;
    display: flex; */
    text-align: center;
    width: 100%;
  }
  
  .terms-list {
    /* list-style-type: disc;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column; */
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;
  }
  
  .terms-list li {
    display: inline-block;
    margin: 0 25px 0 0;
    position: relative;
  }
  
  .terms-list a {
    text-decoration: none;
    color: #000;
    font-size: 14px;
  }
  
 
.terms li {
    position: relative;

    &:after {
      content: "|";
      right: -10px;
      position: absolute;
      color: #000;
    }

    &:last-child:after {
      content: "";
    }
  }
  
/* CONTACT */
.f-contact {
    /* width: 21%; */
    color: #000;
    font-family: 'Jost';
}

/* COPY RIGHT */

.f-copyright {
    text-align: center;
    color: #000;
    font-family: 'Jost';
    font-size: 14px;
    font-family: 'Jost';
    margin: 0;
}

@media screen and (max-width: 768px) {
    .f-social-medias {
        /* width: 28%; */
        padding-right: 5%;
    }

    /* TERMS */
    /* .terms {
        width: 100%;
    } */
}

