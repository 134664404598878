.op-project {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
}

.op-pro-img {
  width: 100%;
  object-fit: cover;
  background-color: #f0f0f06e;
  /* height: 300px; */
}

.op-pro-img > img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
}

.op-pro-detail {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.op-pro-info {
  width: 80%;
    display: flex;
    flex-direction: column;
    text-align: start;
    justify-content: center;
    padding-bottom: 10px;
    padding-left: 15px;
}

.op-prj-title {
  font-family: "Dm Serif Display";
  font-size: 18px;
  margin: 8% 0 4% 0;
  width: 100%;
}

.op-prj-path {
  width: 100%;
  font-size: 14px;
  font-family: "Jost";
  margin: 0;
}

.op-pro-btn {
  width: 18%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.op-pro-btn > a {
  width: 70%;
  display: flex;
  justify-content: center;
}

.op-pro-btn > a > button {
  width: 100%;
  border-radius: 50%;
  padding: 27%;
  background-color: #f4f0ec;
  border: none;
  font-size: 18px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
