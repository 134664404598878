

/* Very small screens (e.g., mobile phones) */
@media (max-width: 480px) {
    .carosuel-text {
        font-size: 15px; /* Adjust as needed */
    }
    .carousel-caption {
        padding: 5% 15% !important;
    }
    .header-logo {
        margin-left: .5rem !important;
    }
    .carosuel-text {
        font-size: 18px !important;
    }
    .header-text {
        margin-left: 0.5rem;
    }
    .header-text {
        font-size: 20px;
    }
    .carosuel-text-in{
        font-size: 12px;
    }
}

/* Smaller screens (e.g., tablets) */
@media (max-width: 767px) {
    .carosuel-text {
        font-size: 17px; /* Adjust as needed */
    }
    
    .carousel-caption {
        padding: 7% 16% !important;
    }
}

/* Larger screens (e.g., desktops) */
@media (min-width: 768px) {
    .carosuel-text {
        font-size: 20px; /* Adjust as needed */
    }
    
    .carousel-caption {
        padding: 8% 17% !important;
    }
}

/* Extra large screens (e.g., large desktops or TVs) */
@media (min-width: 1200px) {
    .hp-pro-img {
        height: 200px;
    }
    .carosuel-text {
        font-size: 35px; /* Adjust as needed */
    }
    
    .carousel-caption {
        padding: 20% 20% !important;
    }
}

.home {
    width: 100%;
    margin: 0 !important;
}
.homeNews {
    width: 100%;
    height: 130vh;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10%;
    border-bottom-left-radius: 25%;
    margin-bottom: 5%;
}

.homeNews>h1 {
    /* width: 30%; */
    /* font-size: 50px; */
    margin: 0;
}

.homeNews>p {
    /* width: 40%; */
    color: #4D5053;
    font-family: 'Jost';
}

.homeNewsContent p {
    text-align: justify;
    padding-right: 25px;
}
.homeNewsContent{
    padding: 5px 15px 5px 5px !important;
    overflow: auto;
}

.carousel-button {
    width: 150px;
    padding: 1% 2%;
    border: none;
    border-radius: 10px;
    font-family: 'Jost';
    background-color: #292F36;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.homeOther {
    /* width: 70%; */
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* HOME PLANS */
.homePlans {
    display: flex;
    justify-content: space-between;
    margin: 5% 5%;
}

.homePlan {
    text-align: center;
    margin: 0 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
/* Default font size */
.carosuel-text {
    font-size: 35px;
    color: #b3d1d8 !important;
}

.carosuel-text-in{
    color: #fff;
}

.carousel-caption{
    color: #252525;
    padding: 20% 20%;
    color: #252525;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}
.homePlan>a {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homePlan>a>button {
    margin: 5% 0;
    width: 100px;
    padding: 4% 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 10px;
    background-color: transparent;
    cursor: pointer;
}

/* HOME PROJECTS */
.homeAboutUs {
    width: 100%;
    margin: 0;
    display: flex;
    background-color: #01677c;
    justify-content: space-between;
}

.homeAboutUs .hp-subtext {
    margin: 30% 5%;
    background-color: #fff !important;
    border-radius: 30px;
    padding: 30px;
}

.homeAboutUs .hp-subtext>p {
    text-align: justify;
    padding-right: 15px;
}

.homeAboutUs .hp-subtext>h1 {
    padding-right: 15px;
}

.hp-img {
    /* width: 100%; */
    object-fit: cover;
    margin: 0;
    padding: 0;
}

.hp-img>img{
    width: 100%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}

/* CALL US */
.callUs {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 0 10% 0;
}

.callUs>.phoneNum {
    display: flex;
    font-family: 'Jost';
    align-items: center;
    padding-left: 15%;
}

.h-icon {
    border: 1px solid white;
    color: #CDA274;
    background-color: #F4F0EC;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    font-size: 20px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2%;
}

.callUs>.phoneNum>a>p {
    font-weight: 600;
}

.callUs>.phoneNum>a>p>span {
    color: #4D5053;
    font-size: 15px;    
    font-weight: 400;
}

.callUs>a {
    /* width: 200px;
    height: 60px; */
    display: flex;
}

.callUs>a>button {
    width: 100%;
    padding: 12% 0;
    margin: 5% 0;
    border: none;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #003c49;
    color: white;
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    height: 15px;
}


/* BRANDS */
.home-brands {
    /* width: 100%; */
    margin: 5% 0;
}
.home-brands-card {
    text-align: center;
}
.home-brands img {
    width: 100px;
    height: 100px;
}

.home-brands>ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

/* .home-brands>ul>ol {
    width: 12%;
}

.home-brands>ul>ol>img {
    width: 100%;
} */
/* PEOPLE THOUGHTS */
.people-thoughts {
    /* width: 94%; */
    padding: 5% 3% 7% 3%;
    background-color: #F4F0EC;
    text-align: center;
    border-radius: 35px;
    margin: 20px;
}

.people-thoughts>h1 {
    margin: 3% auto;
    width: 40%;
}

.people {
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
}
.person-card{
    padding: 5px;
}
.person {
    display: flex;
    flex-direction: column;
    /* width: 26%; */
    text-align: start;
    padding: 5px;
    background-color: white;
    border-radius: 20px;
}

.person-title {
    width: 100%;
    display: flex;
    align-items: center;
}

.person-title>div {
    object-fit: cover;
    width: 27%;
    margin-right: 5%;
}
.person-title>div>img{
    width: 100%;
    border-radius: 50%;
}

.person-title>p {
    font-family: 'Dm Serif Display';
    font-size: 17px;
    width: 100%;
}

.person-title>p>span {
    font-family: 'Jost';
    font-size: 14px;
    color: #4D5053;
}

.thought {
    font-family: 'Jost';
    color: #4D5053;
    font-size: 15px;
}

/* HOME PROJECTS */
.homeProjects {
    width: 100%;
    text-align: center;
}

.homeProjects>h1 {
    margin: 0;
}

.homeProjects>p {
    width: 70%;
    margin: 3% auto;
}

.hp-list {
    margin: 5% 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.hp-project {
    display: flex;
    flex-direction: column;
    /* flex: 1 1 40%; */
    /* width: 45%; */
    margin: 3% 0;
}

.hp-pro-img {
    width: 100%;
    object-fit: cover;
    background-color: #f0f0f0;
    height: 300px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.hp-project-card{
    /* padding: 5px; */
    border: 1px solid #f0f0f0;
    border-radius: 10px;
}

.hp-project-card:hover {
    box-shadow: 0 8px 10px rgb(133 140 148 / 40%), 0 0 2px #c4c7cc;
}

.hp-pro-img>img {
    /* border-radius: 30px;
    width: 100%; */
    height: 100%;
}

.hp-pro-detail {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 10px 10px 10px;
}

.hp-pro-info {
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: start;
    justify-content: center;
}

.hp-prj-title {
    font-family: 'Dm Serif Display';
    font-size: 18px;
    margin: 8% 0 4% 0;
    width: 100%;
    color: #292F36;
}

.hp-prj-path {
    width: 100%;
    font-size: 14px;
    color: #4D5053;
    font-family: 'Jost';
    margin: 0;
    /* width: 50%; */
}

.hp-pro-btn {
    width: 18%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hp-pro-btn>a{
    width: 80%;
    display: flex;
}

.hp-pro-btn>a>button{
    width: 90%;
    border-radius: 50%;
    padding: 27%;
    background-color: #F4F0EC;
    border: none;
    font-size: 18px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

/* HOME EXPERIENCE */

.home-experience {
    margin: 10px 15px;
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    background-color: #F4F0EC;
    /* padding: 5% 15%; */
    /* margin: 5% auto 7% auto; */
}

.home-experience>div {
    text-align: center;
}

.home-experience>div>p:last-child {
    font-family: 'Jost';
}

.home-experience>div>.num {
    font-family: 'Dm Serif Display';
    font-size: 45px;
    margin: 0;
    color: #CDA274;
}
.home-experience-box.ebr-r{
    border-right: 1px solid #CDA274;
}
.home-experience-box{
    padding: 10px;
}
/* .h-s-project
{
    border-right: 1px solid #CDA274;
    border-left: 1px solid #CDA274;
    padding: 0% 8%;
    margin: 0 6% 0 6%;
}
.h-a-project {
    border-right: 1px solid #CDA274;
    padding: 0% 8% 0 0;
    margin: 0 8% 0 0;
} */

/* ARTICLES */
.articleNews {
    width: 100%;
    margin: 15px auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.articleNews>h1 {
    margin: 0;
}

.articleNews>p{
    width: 100%;
    font-family: 'Jost';
    color: #4D5053;
    padding: 15px;
}

.articles {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.article {
    /* width: 28%; */
    /* padding: 2%; */
    border-radius: 30px;
    margin-bottom: 10px;
}

.nochosen {
    background-color: transparent;
}

.chosen {
    background-color: #F4F0EC;
}

.article-header {
    width: 100%;
    object-fit: cover;
}

.article-header>img {
    width: 100%;
    border-top-right-radius: 15%;
    border-top-left-radius: 15%;
}

.article-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: start;
}

.article-content>p{
    font-family: 'Dm Serif Display';
    font-size: 17px;
    width: 80%;
}

.ac-detail {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.ac-detail>p {
    width: 70%;
    text-align: start;
    color: #4D5053;
    font-size: 13px;
    font-family: 'Jost';
}

.ac-detail>a {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.ac-detail>a>button{
    width: 50%;
    border-radius: 50%;
    padding: 15%;
    background-color: #F4F0EC;
    border: none;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

/* INTERNO */
.h-interno {

    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 20px 20%;
    padding: 3% 0;
    background-color: #292F36;
    border-radius: 50px;
    color: white;
}

.h-interno>h1 {
    color: white;
    margin: 0;
}

.h-interno>a {
    text-align: center;
}

.h-interno>a>button {
    color: white;
    padding: 10px;
    border-radius: 15px;
    border: none;
    background-color: #CDA274;
    display: flex;
    justify-content: center;
    align-items: center;
}

.particles-bg-canvas-self{
    height: 150vh !important;

    /* height:  100% !important; */
    /* z-index: 1 !important; */
}
.home-plans-choose .card{
    height: 100% !important;
}
.card-bg{
    background-color: var(--gd-theme-color-white, white);
    border-radius: 3px;
    box-shadow: 0 0 2px 0 #c4c7cc;
    height: 100%;
    /* width: 300px !important; */
    border-radius: 10px;
}
.card-bg:hover {
    box-shadow: 0 8px 10px rgb(133 140 148 / 40%), 0 0 2px #c4c7cc;
}

.carosuel-text{
    color: black ;
    font-weight: bold;

}

.marker:after {
    content: "";
    width: 125px;
    height: 30px;
    bottom: -650px;
    left: 655px;
    position: absolute;
    background-image: url(./marker.svg);
    background-repeat: no-repeat;
}

.theme-color{
    color: #004aad;
}

.card-title{
    padding: 20px 0;
}

.services__item__text__icon {
    height: 70px;
    width: 80px;
    background: #FAD110;
    color: #142336;
    line-height: 70px;
    position: absolute;
    left: 50%;
    top: -45px;
    margin-left: -40px;
    text-align: center;
    -webkit-transition: all, 0.3s;
    -o-transition: all, 0.3s;
    transition: all, 0.3s;
}

.services__item__text__icon:after {
    position: absolute;
    left: 0;
    bottom: -12px;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    border-top: 12px solid #FAD110;
    content: "";
    -webkit-transition: all, 0.3s;
    -o-transition: all, 0.3s;
    transition: all, 0.3s;
}
.card-bg:hover .services__item__text__icon:after {
    border-top: 12px solid #0f6f83;
}
.card-bg:hover .services__item__text__icon {
    background: #0f6f83;
}

.home-plans-choose{
    background-color: #fff4bf;
    margin: 0;
    padding: 5%;
}

.home-plans-choose .home-plans-choose-icon {
    width: 100px;
    height: 100px;
    display: inline-block;
    text-align: center;
    line-height: 100px;
    background-color: #01677c;
    border-radius: 50%;
    position: absolute;
    left: 37%;
    top: -35%;
}
.home-plans-choose .card
{
    border: none;
    border-radius: 30px;
}


/* Hover effect on card */
.home-plans-choose .card:hover .home-plans-choose-icon img {
    animation: moveUpDown 1s ease-in-out infinite alternate;
}
/* Keyframes for up and down movement */
@keyframes moveUpDown {
    0% {
        transform: translateY(-5px);
    }
    25% {
        transform: translateX(5px);
    }
    50% {
        transform: translateY(5px);
    }
    100% {
        transform: translateX(-5px);
    }
}
.mar-top-5{
    margin-top: 5rem;
}

.home-plan-service .card-body{
    position: relative;
}

.service-link-btn{
    display: flex;
    justify-content: center;
}