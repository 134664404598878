


.header {
    width: 100%;
    padding: 1rem 0;
    position: fixed !important;
    z-index: 9999;
    background: linear-gradient(90deg, rgba(255,255,255,1) 23%, rgba(1,103,124,1) 100%);
}

.header .container-fluid {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.img-fluid{
    height: 60px !important;
}

a {
    text-decoration: none;
    color: black;
}

.header-brand {
    margin-right: 1rem; /* Add some space on the right */
}

.navbar-toggler {
    margin-right: 1rem !important;
}
    
@media (max-width: 1000px) {
    .navbar-collapse.collapsing .nav-link, .navbar-collapse.show .nav-link {
        padding: 0.5rem 2rem;
        margin: 0;
        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 23%, rgba(1, 103, 124, 1) 100%);
    }
    .navbar-collapse.collapsing .nav-link:hover, .navbar-collapse.show .nav-link:hover {
        background-color: #a7a7a7;
    }
    .carousel-caption {
        right: 0px !important;
        left: 0px !important;
    }
}

.justify-content-center.navbar-collapse.collapse.show{
    border-top: 1px solid rgb(164, 201, 208) !important;
}
.navbar-toggler:focus {
    box-shadow: 0 0 20px 0rem !important;
}

.collapse.show .nav-link:hover {
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 23%, rgb(126 178 189) 100%) !important;
}
.header-logo-text {
    font-family: 'DM Serif Display';
    font-size: 22px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
a{
    text-decoration: none !important;
}
.header-text {
    margin-left: 1rem;
    padding-top: 10px !important;
    color: black;
    font-family: 'Dm Serif Display' !important;
}

.header-text p{
    font-family: monospace;
    text-transform: uppercase;
    font-weight: 600;
    color: #01677c;
    font-size: 20px;
}

.navbar-toggler {
    background-color: #ffffff !important;
}
.nav-link{
    font-size: 16px !important;
    color: #ffffff !important;
    font-weight: 600 !important;
}
@media (max-width: 1000px) {
    .nav-link{
        font-size: 16px !important;
        color: #01677c !important;
        font-weight: 600 !important;
    }
}

.header-logo {
    margin-left: 2rem;
    display: flex;
    align-items: center;
}

.header-logo > img {
    width: 100%;
}

.header-pages {
    display: flex;
}

.nav-link {
    font-family: Jost;
    font-weight: 400;
    margin-right: 1.5rem;
}
.carousel {
    top: 82px !important;
    margin-bottom: 82px !important;
}
@media (min-width: 992px) {
    .navbar-nav {
        margin-left: auto;
        /* margin-right: auto; */
    }
}

.header.navbar  {
    border-bottom: 1px solid #a4c9d0;
    box-shadow: 0 8px 10px rgb(133 140 148 / 15%), 0 0 2px #ffffff;
}


@media (min-width: 768px) {
    .header-text p{
        font-size: 20px;
    }
}
  
@media (min-width: 1200px) {
    .header-text p{
        font-size: 24px;
    }
}  

@media (max-width: 767px) {
    .header-text p{
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .header-text p{
        font-size: 17px;
    }
}
