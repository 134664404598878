@media screen and (max-width: 768px){
    
    .person-card{
        padding: 15px;
    }
    /* .homeOther { */
        /* width: 80%;  */
    /* } */
    .homeNews>a>button {
        width: 150px;
        padding: 2% 2%;
    }

    /* HOME EXPERIENCE */
    /* .home-experience{
        width: 72.5%;
    } */

    
    .home-experience>div>.num {
        font-family: 'Dm Serif Display';
        font-size: 25px;
        margin: 0;
        color: #CDA274;
    }
    .home-experience>div>p {
        font-size: 12px;
    }
    /* CALL US */
    .callUs {
        flex-direction: row;
    }
    /* .callUs>a>button {
        width: 100%;
        padding: 0% 0;
    } */
    /* PEOPLE THOUGHTS */
    .people-thoughts>h1 {
        width: 60%;
        font-size: 30px;
    }
    .person-title>div {
        width: 28%;
        margin-right: 3%;
    }
    .person-title>p {
        width: 65%;
        font-size: 14px;
        margin: 2% 0;
    }
    .person-title>p>span {
        font-size: 12px;
    }

    /* ARTICLES */
    .article-content>p{
        font-size: 15px;
        width: 90%;
    }

    /* INTERNO */
    .h-interno>h1 {
        font-size: 28px;
    }
    .h-interno>p {
        font-size: 13px;
    }
    
    /* .hp-img {
        display: none;
    } */
    .h-interno {
        margin: 15px;
        border-radius: 25px;
    }
    }